import restaurant from "./images/bucate.jpg";
import img_card from "./images/tata.jpg";
import img_nasi from "./images/tata.jpg";
import img_bucatar from "./images/1.jpg";
import img_music from "./images/2.jpg";
import imgOmSuflet from "./images/vin3.jpg";
import imgOmSufletmb from "./images/vin3.jpg";
import imgheader from "./images/g.avif";
import imgheaderm from "./images/bg2.jpg";
import imgheadermiini from "./images/robloxh.jpg";
import imgheadermiinimb from "./images/robloxm.jpg";
import imgconfirmare from "./images/minioni.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "",
        mireasa: "",
        tata: "Ivan",
        mama: "",
        copilul: "Ivan",
        familia: "",
        title: "Invitatie Online",
        data: "17 Februarie 2023",
        data_confirmare: "",
        savedata: "Vă invit!",
        ani: "Ani",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "anusic007@gmail.com", 
       tel: "+37367373585",
       phone: "tel:+37367373585",
       viber: "viber://chat?number=%2B37367373585",
       whatsapp: "https://wa.me/+37367373585",
       messenger: "https://www.messenger.com/t/ivan.ciuvalschi",
       tel1: "+40740907712",
       phone1: "tel:+40740907712",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+40740907712",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Acasă",
            data: "17 februarie 2023, vineri, ora 18:30",
            adress: "str. Șoimilor 3A, Durlești",
            harta: "https://goo.gl/maps/s6ME8opjapSsuujG9",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d223.62609150991506!2d28.771577184093537!3d47.03095250098954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97df91f6e1e5b%3A0xc99fe2ee088fba00!2zU3RyYWRhIMiYb2ltaWxvciwgQ2hpyJlpbsSDdSwg0JzQvtC70LTQsNCy0LjRjw!5e0!3m2!1sru!2s!4v1676120231841!5m2!1sru!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Invitație!",
            title2: "VĂ INVITĂM LA ZIUA NOASTRĂ!",
            message: "Pentru că împlinesc 12 ANI, te invit cu mare drag, să sărbătorim împreună, cu muzică, dans și multe suprize!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_nasi,
            title1: "Salutare tuturor!",
            title2: "Am un anunț de făcut:",
            message: 'Încurând schimb prefixul din 6 în 7 și vreau să petrec alături de voi cu această ocazie. O vârstă atât de rotundă, merită să fie sarbătorită de cei dragi inimii!',
            title3: 'Vă aștept cu mare drag!'
        },
    ],
    blogDataBucatar: [
        {
            id: 2,
            img: img_bucatar,
            title1: "Vă invităm alături de cea mai bună bucătăreasă:",
            title2: "Natalia Căpătici Vizitiu",
            message: "Și să gustăm din bucatele delicioase a mânuților de aur!",
        }
    ],
    blogDataMusic: [
        {
            id: 2,
            img: img_music,
            title1: "Sub conducerea muzicală de:",
            title2: "Deejay Vibe",
            message: "Distracția și dansurile sunt asigurate până dimineață!",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            img1: imgOmSufletmb,
            title: "Cu mult drag și încântare",
            message: "Vă invit la o petrecere tare!",
            nasii: "Cu muzică și voie bună",
            nasii_nume: "Ne vom distra clar împreună!",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "12 ANI",
            message: "LA MULȚI ANI!",
        }
    ],
    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ], 

}

export default data;